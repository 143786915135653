/**
 * 解析 User Agent
 * @param ua 用户代理字符串
 */
function parse(ua) {
    const isPlus = /html5plus/i.test(ua);
    const _platform = (/Mozilla\/.*? \((.*?)\)/i.exec(ua) || [])[1] || '';
    const isIOS = /iP(hone|ad|od)/i.test(_platform);
    const isAndroid = /Android/i.test(_platform);
    const isWindows = /Windows NT /i.test(_platform);
    const isMac = /Macintosh/i.test(_platform);
    const isLinux = !isAndroid && /Linux/i.test(_platform);
    const platform = isPlus && isIOS ? 'ios' : isPlus && isAndroid ? 'android' : 'web';
    const WIN_VERSION_DICT = {
        '5.1': 'XP',
        '5.2': 'XP',
        '6.0': 'Vista',
        '6.1': '7',
        '6.2': '8',
        '6.3': '8.1',
        '10': '10',
        '10.0': '10',
    };
    // '系统 版本号'
    const os = (() => {
        var _a;
        // plus
        try {
            const { name, version } = (_a = window['plus']) === null || _a === void 0 ? void 0 : _a.os;
            if (name && version) {
                return [name, version].filter(Boolean).join(' ');
            }
        }
        catch (error) {
            // nothing to do
        }
        if (isIOS) {
            return 'iOS' + _platform.replace(/^.*OS ([-\d_.]+).*$/i, (_, $1) => ` ${$1.replace(/_/g, '.')}`);
        }
        if (isAndroid) {
            return 'Android' + _platform.replace(/^.*Android ([-\d.]+).*$/i, ' $1');
        }
        if (isWindows) {
            return 'Windows' + _platform.replace(/^.*Windows NT ([-\d.]+).*$/i, (_, $1) => ` ${WIN_VERSION_DICT[$1] || 'NT' + $1}`);
        }
        if (isMac) {
            return 'Mac' + _platform.replace(/^.*Mac OS X ([-\d_.]+).*$/i, (_, $1) => ` ${$1.replace(/_/g, '.')}`);
        }
        if (isLinux) {
            return 'Linux Unknown';
        }
        return 'Other Unknown';
    })();
    const browser = (() => {
        // 微信
        if (/ MicroMessenger\//i.test(ua)) {
            return 'MicroMessenger' + ua.replace(/^.* MicroMessenger\/([\d.]+).*$/i, ' $1');
        }
        // Android App
        if (isPlus && isAndroid) {
            return 'Android-Webview' + ua.replace(/^.*Chrome\/([\d.]+).*$/i, ' $1');
        }
        // iOS
        if (isIOS) {
            return 'iOS-Webview ' + os.split(' ')[1];
        }
        // Firefox
        if (/ Firefox\//i.test(ua)) {
            return 'Firefox' + ua.replace(/^.* Firefox\/([\d.]+).*$/i, ' $1');
        }
        // Edge
        if (/ Edge\//i.test(ua)) {
            return 'Edge' + ua.replace(/^.* Edge\/([\d.]+).*$/i, ' $1');
        }
        // New Edge
        if (/ Edg\//i.test(ua)) {
            return 'Edge' + ua.replace(/^.* Edg\/([\d.]+).*$/i, ' $1');
        }
        // Safari & Safari like
        if (isMac && / Version\/[\d.]+ Safari\/[\d.]+/i.test(ua)) {
            return 'Safari' + ua.replace(/^.* Version\/([\d.]+).*$/i, ' $1');
        }
        // Chrome & Chrome like
        if (/ Chrome\//i.test(ua)) {
            return 'Chrome' + ua.replace(/^.* Chrome\/([\d.]+).*$/i, ' $1');
        }
        // 无头 Chrome，自动化脚本专用
        if (/ HeadlessChrome\//i.test(ua)) {
            return 'HeadlessChrome' + ua.replace(/^.* HeadlessChrome\/([\d.]+).*$/i, ' $1');
        }
        // IE
        if (/Trident\/.*rv:11.0.*\) like Gecko/i.test(ua)) {
            return 'IE 11';
        }
        if (/ MSIE [\d.]+/i.test(ua)) {
            return 'IE' + ua.replace(/^.* MSIE ([\d.]+).*$/i, ' $1');
        }
        return 'Other Unknown';
    })();
    const webview = (() => {
        if (isIOS) {
            return 'iOS-Webview ' + os.split(' ')[1];
        }
        if (isAndroid && /Chrome/.test(ua)) {
            return 'Android-Webview' + ua.replace(/^.*Chrome\/([\d.]+).*$/i, ' $1');
        }
        return browser;
    })();
    const device = (() => {
        var _a, _b, _c;
        try {
            const { vendor, model } = (_a = window['plus']) === null || _a === void 0 ? void 0 : _a.device;
            return [vendor, model].filter(Boolean).join(' ');
        }
        catch (error) {
            // nothing to do
        }
        if (/^IE/.test(browser)) {
            return ((_b = /\((.*?) Trident.*?\)/.exec(ua)) === null || _b === void 0 ? void 0 : _b[1]) || 'Other Unknown';
        }
        return ((_c = /\((.*?)\)/.exec(ua)) === null || _c === void 0 ? void 0 : _c[1]) || 'Other Unknown';
    })();
    return { ua, platform, os, browser, webview, device };
}

const lang = /zh.*CN/i.test(navigator.language) ? 'zh' : 'en';
const ua = navigator.userAgent;
const { os, browser } = parse(ua);
const isPlus = /Html5Plus/i.test(ua);

function isFunction(o) {
    return typeof o === 'function';
}

function isES5Supported() {
    return (isFunction(Object.keys) &&
        isFunction(Object.defineProperty) &&
        isFunction(Object.defineProperties) &&
        isFunction(Object.create) &&
        isFunction([].map) &&
        isFunction([].filter) &&
        isFunction([].reduce) &&
        isFunction(window.addEventListener) &&
        isFunction(document.addEventListener) &&
        isFunction(document.querySelector) &&
        isFunction(document.querySelectorAll));
}

// 不兼容 ES5 的浏览器、IE 10及以下、微信桌面版强制跳转到 “更换/下载浏览器” 页面
const UPGRADE_URL = `//iweb.angelalign.com/pages/detect/upgrade-browser-${lang}.html?from=${encodeURIComponent(location.href)}`;
const shouldUpgrade = () => {
    // 移动端暂时不做检测
    if (isPlus || /^iOS/.test(os)) {
        return;
    }
    // 特性检测
    // 兼容 ES5
    if (!isES5Supported()) {
        return true;
    }
    // 版本检测
    // IE
    if (/^Windows/.test(os) && /^IE/.test(browser)) {
        return true;
    }
    // 桌面微信浏览器
    if (/^Windows|Mac/.test(os) && /^MicroMessenger/.test(browser)) {
        return true;
    }
    // 其它浏览器
    const browserPair = browser.split(' ');
    const name = browserPair[0];
    const version = parseInt(browserPair[1]);
    if (name === 'Chrome' || name === 'Firefox') {
        if (version < 49) {
            return true;
        }
    }
    if (name === 'Safari') {
        if (version < 11) {
            return true;
        }
    }
    if (name === 'Edge') {
        if (version < 79) {
            return true;
        }
    }
};
const showNotice = () => {
    location.href = UPGRADE_URL;
};

var version = "1.4.1";

// 渐进式判定
function autoDetect() {
    if (shouldUpgrade()) {
        return showNotice();
    }
    window.addEventListener('load', () => {
        const script = document.createElement('script');
        document.body.appendChild(script);
        script.src = `//iweb.angelalign.com/jslib/detect/${version}/advise-compatibility-detect.js`;
    });
}
autoDetect();
